.c-checkbox {
    &__checked {
        font-weight: 600;
    }
    // Inspired by https://www.w3schools.com/howto/howto_css_custom_checkbox.asp.
    /* Customize the label (the container) */
    .container {
        width: 300px;
        display: flex;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 16px;
        line-height: 29px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        margin-left: 0;

        @media only screen and (min-width: 200px) and (max-width: 1279px) {
            width: unset;
        }
    }
    /* Hide the browser's default checkbox */
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }
    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 3px;
        left: 0;
        height: 17px;
        width: 17px;
        background-color: white;
        border: 1px solid $color-border-primary;
    }
    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
        // background-color: #ccc;
    }
    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
        background-color: $color-linkblue;
        border: 1px solid $color-linkblue;
    }
    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: '';
        position: absolute;
        display: none;
    }
    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
        display: block;
    }
    /* Style the checkmark/indicator */
    .container .checkmark:after {
        left: 5px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

.c-checkbox-list {
    /*MOBILE STYLES*/
    @media only screen and (min-width: 200px) and (max-width: 1279px) {
        padding: 0 !important;
    }
}
