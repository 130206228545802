.c-category-list {
    height: 430px;
    margin-top: 40px;
    margin-bottom: 40px;
    margin-right: 20px;

    @include desktop {
        &:last-child {
            margin-right: 0;
        }
    }
    & {
        display: flex;
        flex-direction: column;
        flex-basis: 33%;
        max-width: 400px;
    }

    /*MOBILE STYLES*/
    @media only screen and (min-width: 200px) and (max-width: 1279px) {
        padding: 20px 0;
        margin: 0 20px;
        border-top: 1px solid #bababa;
        color: #333333;
        max-width: unset;
    }

    &__expandFilterOptions {
        /*MOBILE STYLES*/
        @media only screen and (min-width: 200px) and (max-width: 1279px) {
            transition: height 0.2s ease-in-out;
            height: 420px !important;
            padding: initial !important;
            display: block;
        }

        &__longList {
            height: 420px !important;
        }
    }

    &__title {
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        display: flex;

        & h4 {
            color: $color-darkgray;
            font-size: 18px;
            letter-spacing: normal;
        }
    }

    &__expand {
        width: 15px;
        object-fit: contain;
    }

    &__mobileheader {
        display: none;
        /*MOBILE STYLES*/
        @media only screen and (min-width: 200px) and (max-width: 1279px) {
            display: block;
            margin: 0 20px 20px;
        }

        & {
            color: $color-darkgray;
            font-size: 18px;
            letter-spacing: normal;
        }
    }

    &__heading {
        font-weight: bold;
        line-height: 24px;
        margin-bottom: 10px;
    }

    &__container {
        border: 1px solid #baba;
        background-color: white;
        height: 100%;
        overflow-y: auto;
        padding: 20px;
        /*MOBILE STYLES*/
        @media only screen and (min-width: 200px) and (max-width: 1279px) {
            border: none;
            height: 0;
            padding: 0;
            background: none;
            transition: height 0.2s ease-in-out;
        }
    }

    &__helptext {
        font-size: 16px;
        color: #454545;
    }
}
