//@import 'colors';
.c-searchresult-container {
  width: 100%;
}
.c-searchresult {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__header {
        border-bottom: 1px solid #030000;
        margin-bottom: 20px;
        padding-bottom: 15px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        /*MOBILE STYLES*/
        @media only screen and (min-width: 200px) and (max-width: 1279px) {
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }

    &__numberofhits {
    }

    &__item {
        background-color: white;
        padding: 20px 0;
        border-bottom: 1px solid #b6b6b7;

        &__summary {
            margin: 0;
        }

        & > a {
            text-decoration: none;
        }

        & > a:hover {
            text-decoration: none;
        }

        &__header {
            font-size: 18px;
            padding-bottom: 20px;
            display: flex;
            /*MOBILE STYLES*/
            @media only screen and (min-width: 200px) and (max-width: 1279px) {
                flex-direction: column;
            }

            &__title {
                font-weight: bold !important;
                color: #375e7f;
                margin: 0;
            }

            &:hover {
                text-decoration: underline;
            }
        }

        &__referatnumber {
            margin: 0;
            margin-right: 20px !important;
            font-weight: bold !important;
            color: #375e7f;
            /*MOBILE STYLES*/
            @media only screen and (min-width: 200px) and (max-width: 1279px) {
                margin-bottom: 10px !important;
            }
        }
    }
}

.sortorder-dropdown {
  color: #375e7f;
}
