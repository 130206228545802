$disabled_button_color: #ccc;


.c-referat-body {
    & p {
        margin: 0;
    }
}
.c-paginator {
    width: 100%;
    margin: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    /*MOBILE STYLES*/
    @media only screen and (min-width: 200px) and (max-width: 1279px) {
        width: 100%;
    }

    & div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        width: 33%;

        & p {
            margin: 0;
        }
    }

    & div:last-of-type {
        justify-content: flex-end;
    }

    & div:first-of-type {
        justify-content: flex-start;
    }

    &__button {
        font-size: 18px;
        height: 55px;
        width: 177px;
        padding: 10px 20px;
        font-weight: 600;
        border-radius: 50px;
        color: white;
        background: #375e7f;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        /*MOBILE STYLES*/
        @media only screen and (min-width: 200px) and (max-width: 1279px) {
            width: 100%;
            justify-content: center;
            align-items: center;
        }

        &:hover {
            background: #1b3d5a;
            color: #fff;
        }

        &__disabled {
            border: 1px solid $disabled_button_color;
            color: $disabled_button_color;
            cursor: not-allowed;

            &:hover {
                background: transparent;
                border: 1px solid $disabled_button_color;
                color: $disabled_button_color;
                cursor: not-allowed;
            }
        }
    }

    &__currentpage {
        line-height: 40px;
        margin-left: 30px;
        margin-right: 30px;
    }
}
