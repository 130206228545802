.c-emptyresult {
  height: 40px;
  color: red;
  font-size: 16px;
  background-color: white;

  &__hide {
    visibility: hidden;
  }

  &__show {
    visibility: visible;
  }
}
