$background-color: #e8ebf1;
$button-color: #599674;

.c-searchbox-container {
    padding-bottom: 10px;
    background-color: white;
    position: relative;
    width: 1240px;
    margin: 0 auto;
    padding: 0;
    /*MOBILE STYLES*/
    @media only screen and (min-width: 200px) and (max-width: 1123px) {
        width: initial;
    }

    @media only screen and (min-width: 1124px) and (max-width: 1279px) {
        width: 970px;
    }

    .c-searchbox {
        background-color: white;
        padding-bottom: 20px;
        position: relative;

        &__cross {
            position: absolute;
            content: '';
            width: 21px;
            width: 34px;
            right: 15px;
            height: 62px;
            display: flex;
            justify-content: center;
            align-items: center;

            &__cross-1 {
                position: absolute;
                transform: rotate(45deg);
                border-radius: 50px;
                background-color: $color-darkgray;
                height: 4px;
                width: 21px;
            }

            &__cross-2 {
                position: absolute;
                transform: rotate(-45deg);
                border-radius: 50px;
                background-color: $color-darkgray;
                height: 4px;
                width: 21px;
            }
        }

        &::before {
        }

        &::after {
            transform: rotate(-45deg);
        }

        & form {
            display: flex;
        }

        &__input {
            font-size: 18px;
            border: 1px solid $color-border-primary;
            background: #fff !important;
            height: 60px;
            width: 100%;
            border-radius: 50px;
            margin-right: 20px;
            padding: 0 10px 0 50px;
            background: white url(../Images/search-psn.svg) no-repeat 19px 19px !important;
            background-size: 20px 20px;
            -moz-background-size: 20px 20px;
            -webkit-background-size: 20px 20px;

            &::-webkit-search-cancel-button {
                display: none;
            }
            /*MOBILE STYLES*/
            @media only screen and (min-width: 200px) and (max-width: 1123px) {
                padding-right: 47px;
                margin: 0;
            }
        }

        &__button {
            padding: 0 30px;
            background: #517a9b;
            border: 1px solid #fff;
            font-weight: bold;
            height: 60px;
            border-radius: 50px;
            color: #fff;
            width: 170px;
            font-size: 18px;
            cursor: pointer;
            margin-right: 20px;
            transition: all 0.2s ease-in-out;

            &:hover {
                background: #1b3d5a;
                color: #fff;
                transition: all 0.2s ease-in-out;
            }
            /*MOBILE STYLES*/
            @media only screen and (min-width: 200px) and (max-width: 1123px) {
                display: none;
            }
        }

        &__reset_button {
            padding: 0 30px;
            background: transparent;
            border: 1px solid #517a9b;
            height: 60px;
            border-radius: 50px;
            color: #517a9b;
            width: 170px;
            font-size: 18px;
            cursor: pointer;
            transition: all 0.2s ease-in-out;
            font-weight: bold;

            &:hover {
                background: #1b3d5a;
                color: #fff;
                transition: all 0.2s ease-in-out;
            }
            /*MOBILE STYLES*/
            @media only screen and (min-width: 200px) and (max-width: 1123px) {
                display: none;
            }
        }
    }
}
