// @import '../shared/nice-select/css/nice-select';

.c-dropdown {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 18px;

  p {
    margin: 0;
    font-weight: bold;
  }

  &__header {
    font-weight: normal;
    width: 100px;
    float: left;
    margin-top: 8px;
  }
  // & .nice-select {
  //   font-size: 16px;
  //   background-color: transparent;
  //   position: relative;
  //   border: none;
  //   border-radius: 0;
  //   font-weight: 600;
  //   float: right;
  //   width: 150px;
  //   & .option.focus,
  //   .option:hover {
  //     background-color: #dce8f1;
  //   }
  //   & .list {
  //     border-radius: 0px;
  //     box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  //   }
  //   &:after {
  //     content: '';
  //     border-left: 5px solid transparent;
  //     border-right: 5px solid transparent;
  //     border-bottom: 5px solid black;
  //     height: 0;
  //     width: 0;
  //     pointer-events: none;
  //     transform: rotate(180deg);
  //   }
  //   &.open:after {
  //     transform: rotate(0deg);
  //   }
  // }
}
