@import '../components-react/colors.scss', '../components-react/categories/c-category-list.scss', '../components-react/checkboxlist/c-checkbox-list.scss',
  '../components-react/dropdown/c-dropdown.scss', '../components-react/emptyresult/c-emptyresult.scss', '../components-react/searchbox/c-searchbox.scss',
  '../components-react/searchresult/c-paginator.scss', '../components-react/searchresult/c-searchresult.scss', '../components-react/variables.scss',
  '../../../../../../Content/PSN/css/abstracts/_mixins.scss';

$background-color: #f8f8f8;
$button-color: #599674;

.app {
  font-weight: 400;
}

.category-lists-frame {
    background-color: $background-color;
    margin: 0 -30px;
    margin-bottom: 40px;

    @media only screen and (min-width: 1124px) and (max-width: 1279px) {
        display: flex;
        justify-content: center;
    }
}

.category-lists-container {
    display: flex;
    justify-content: center;
    flex-direction: row;

    @media only screen and (min-width: 200px) and (max-width: 1123px) {
        padding: 20px 0;
        width: 100%;
        flex-direction: column;
    }

    @media only screen and (min-width: 1124px) and (max-width: 1279px) {
        padding: 20px 0;
        width: 970px;
        flex-direction: column;
    }
}

.page.referat {

    @media only screen and (min-width: 200px) and (max-width: 1123px) {
          padding: 30px;
    }

    & .backlink {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;
        text-decoration: none;
        margin-left: 15px;

        &:before {
            width: 7px;
            height: 7px;
            position: absolute;
            left: 0px;
            top: 8px;
            content: ' ';
            border-top: 2px solid #4174a4;
            border-left: 2px solid #4174a4;
            transform: rotate(-45deg);
        }
    }

    & .printlink {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 14px;
        line-height: 26px;
        text-decoration: none;
        float: right;
    }

    & .text_area.bg_grey {
        padding: 20px;
        font-weight: normal;
        margin-bottom: 30px;
    }

    & .navigate_to_top {
        right: -100px;
        width: 250px;
    }

    & article {
        padding-bottom: 30px;


        @media only screen and (min-width: 200px) and (max-width: 1123px) {
            margin-top: 50px;
        }
    }

    & .toolbar {
        padding-bottom: 30px;
    }
}

.clear-filter {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  padding: 0 20px;
}
